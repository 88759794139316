import React from "react"
import video from "../assets/video/hyperwave_AST_SLOW.mp4"

const VideoBackground = () => (
  <div className="video-background">
    <video loop autoPlay muted playsInline>
      <source src={video} type="video/mp4" />
      />
    </video>
    <span className="video-background-toner"></span>
  </div>
)
export default VideoBackground
