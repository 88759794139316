import React, { useEffect, useRef } from "react"
import logo from "../assets/images/logo.svg"
import toorcon from "../assets/images/toorcon.svg"

import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoBackground from "../components/VideoBackground"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

const IndexPage = () => {
  var flipdownRef = useRef()
  var moreInfoRef = useRef()

  const scrollToForm = function () {
    gsap.to(window, { duration: 1, scrollTo: moreInfoRef.current })
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
    new global.FlipDown(1634058000, flipdownRef.current.id)
      .start()
      .ifEnded(() => {
        console.log("The countdown has ended!")
      })
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      <div className="countdown">
        <div className="countdown-info container">
          <div className="row align-items-center mb-36">
            <div className="countdown-logo col-md-5 offset-md-1 col-sm-6 offset-sm-0 col-12">
              <img
                src={logo}
                width="auto"
                height="50"
                alt="Advanced Security Training"
              />
            </div>
            <div className="countdown-logo col-md-5 col-sm-6 col-12">
              <img src={toorcon} height="100" width="auto" alt="ToorCon" />
            </div>
          </div>
          <div className="row mb-48">
            <div className="col">
              <h1 className="countdown-title text-center">
                Embedded CTF
                <br />
                Escape from San Beagle
              </h1>
            </div>
          </div>
          <div className="row mb-48">
            <div className="col">
              <h4 className="text-center mb-36 font-base">Time To Start</h4>
              <div className="flipdown" id="flipdown" ref={flipdownRef}></div>
            </div>
          </div>
          <div className="row mb-24">
            <div className="col-sm-6 offset-sm-3 col-10 offset-1">
              <a
                target="_blank"
                rel="noreferrer"
                className="btn large accent cta"
                href="https://advancedsecurity.training/training/live-toorcon-ctf/?event=live-toorcon-ctf-2021"
              >
                Register Now
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="countdown-more btn raw"
                onClick={() => {
                  scrollToForm()
                }}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
        <VideoBackground />
      </div>
      <div className="page-section -secondary" ref={moreInfoRef}>
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1">
              <div className="row">
                <p>
                  It’s 2021. A pandemic has spread through the world resulting
                  in the collapse of corporations and social breakdown. This has
                  caused among other things, a devastating chip shortage in the
                  silicon industry. Supply shortages have caused a faction of
                  scavengers (“scavs”) that find electronics components and
                  repurpose them to keep their cars going, their refrigerators
                  running, and their espresso machines brewing. The most coveted
                  component is this device called a “Pocket Beagle” which runs a
                  full Linux-powered distribution in a key fob form factor.
                  Fortunately, you found one, but only if you could reverse
                  engineer it.
                </p>
                <h3 className="mb-24 mt-36">Outline</h3>
                <ul className="list disc">
                  <li>Day 1: embedded CTF</li>
                  <li>Day 2: winner announced</li>
                  <li>
                    Day 3: Josh and Dmitry will have a live stream explaining
                    all the embedded flags and where they can commonly be found
                    in embedded products.
                  </li>
                </ul>
                <h4 className="mb-24 mt-24">
                  Requirements for ToorCon Attendees
                </h4>
                <p>
                  Participants attending Toorcon in person will be provided a{" "}
                  <a
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://beagleboard.org/pocket"
                  >
                    BeagleBone PocketBeagle
                  </a>
                  , including a flashed SD card and any additional hardware to
                  participate in the eCTF.
                </p>
                <h4 className="mb-24 mt-24">
                  Requirements for Virtual Attendees
                </h4>
                <p>
                  Virtual attendees will be provided a download link through the
                  online platform to download the eCTF image and load it onto
                  the SD card.
                </p>
                <br />
                <br />
                <p>
                  Participants attending virtually will need to purchase the
                  following items to participate in the CTF:
                </p>
                <ul className="list disc">
                  <li>
                    <a
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.mouser.com/ProductDetail/BeagleBoard-by-GHI/PocketBeagle-SC-569?qs=%2Fha2pyFaduh6dI0PWQty640pSpm64eaoDB5UWfPbgZjrS8%2FuFAsvPm1KWXtP%252BVtI"
                    >
                      BeagleBone PocketBeagle
                    </a>
                  </li>
                  <li>
                    A 32GB micro SD card, for example{" "}
                    <a
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://amzn.to/3jbu1g0"
                    >
                      Samsung 32GB Evo Plus microSD Card
                    </a>
                  </li>
                  <li>
                    A microSD reader/writer, for example{" "}
                    <a
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://amzn.to/3j9L4PM"
                    >
                      Sandisk USB microSD reader/writer
                    </a>
                  </li>
                  <li>A micro USB cable</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
